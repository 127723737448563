import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    screenSize: "",
    token: localStorage.getItem("token"),
    userInfo: JSON.parse(sessionStorage.getItem("userInfo"))
  },
  mutations: {
    changeScreen(state, screenWidth) {
      state.screenSize = screenWidth > 900 ? "lg" :
        screenWidth > 500 ? "md" : "sm";
    },
    setToken: (state, token) => {
      state.token = token;
      localStorage.setItem("token", token);
    },
    setUserInfo: (state, userInfo) => {
      state.userInfo = userInfo;
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    removeUserInfo: (state) => {
      localStorage.setItem("token", '');
      sessionStorage.setItem("userInfo", JSON.stringify(''));
      state.userInfo = {};
    }
  },
})