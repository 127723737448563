<template>
  <page-frame :header="path">
    <el-skeleton
      :loading="loading"
      animated
      style="text-align: left; margin: 40px 60px"
    >
      <template slot="template">
        <div>
          <div class="people-info">
            <el-skeleton-item variant="image" class="people-avatar" />
            <div><el-skeleton-item variant="text" style="width: 150px" /></div>
            <div><el-skeleton-item variant="text" style="width: 120px" /></div>
            <div><el-skeleton-item variant="text" style="width: 150px" /></div>
          </div>
          <div class="people-detail">
            <div style="width: 700px" />
            <el-skeleton :rows="12" animated />
          </div>
        </div>
      </template>
      <template>
        <div>
          <div class="people-info" style="margin-top: 20px;">
            <!-- 编辑模式：人像上传 -->
            <el-upload
              v-if="editMode"
              class="avatar-uploader"
              action
              :http-request="uploadAvatar"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="people.avatar"
                :src="people.avatar"
                class="people-avatar"
              />
              <div v-else class="people-avatar" style="background-color: gray">
                <i
                  class="el-icon-plus avatar-uploader-icon"
                  style="font-size: 25px; margin-top: 60px"
                />
              </div>
            </el-upload>

            <!-- 非编辑模式：人像 -->
            <img
              v-else
              :src="people.avatar"
              alt="preview image"
              class="people-avatar"
            />

            <div style="font-size: 30px">{{ people.username }}</div>
            <div style="font-size: 22px">
              {{ $t("people." + people.level) }}
            </div>
            <div>{{ people.email }}</div>
            <div style="margin-top: 30px;"></div> 
            <div v-if="people.id==11" style="max-width: 220px;">
              <span style="font-style: italic; font-weight: bold; color: #FFFF00;">{{ $t("home.recruit") }}</span>
            </div>
            <div v-if="editMode" style="margin-top: 40px">
              <div>姓名：<el-input v-model="people.username" /></div>
              <div>
                位置：
                <el-select v-model="people.level" placeholder="请选择">
                  <el-option
                    v-for="item in positions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>邮箱：<el-input v-model="people.email" /></div>
              <div>
                人像图链接：
                <el-input v-model="people.avatar" placeholder="人像图的链接" />
              </div>
            </div>
          </div>
          <div class="people-detail">
            <div v-if="$store.state.token">
              <el-button
                @click="
                  () => {
                    editMode = !editMode;
                  }
                "
              >
                {{ editMode ? "关闭编辑" : "打开编辑" }}
              </el-button>
              <el-button v-if="editMode" @click="commit">保存</el-button>
              <el-button v-if="editMode && this.id" @click="deletePeople">
                删除
              </el-button>
            </div>
            <div
              v-for="(item, index) in [
                'introduction',
                'course',
                'work',
                'education',
                'research',
                'project',
                'paper',
              ]"
              :key="index"
              style="margin: 40px auto"
            >
              <div
                v-if="people[item] || (editMode && $store.state.token)"
                class="markdown-content"
              >
                <h2 style="display: inline-block">
                  {{ $t("people." + item) }}
                </h2>
                <hr />
                <markdown-it-vue-light
                  :content="people[item]"
                  style="margin: 20px 0"
                />
                <mavon-editor
                  v-if="editMode"
                  v-model="people[item]"
                  :subfield="false"
                  :ref="item"
                  @imgAdd="mavonImgAdd[item]"
                  @imgDel="mavonImgDel"
                  style="margin-bottom: 40px"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-skeleton>
  </page-frame>
</template>
<script>
import PageFrame from "../components/PageFrame.vue";
import MarkdownItVueLight from "markdown-it-vue/dist/markdown-it-vue-light.umd.min.js";

export default {
  name: "PeopleDetail",
  components: { PageFrame, MarkdownItVueLight },
  props: { id: String },
  methods: {
    uploadAvatar(param) {
      const img = new FormData();
      img.append("file", param.file);
      this.$axios
        .post("/picture/upload", img, {
          headers: {
            Authorization: this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.people.avatar = res.data.data;
          this.$message({ message: "上传成功", type: "success" });
        })
        .catch((err) => {
          var re = new RegExp(
            "(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]"
          );
          const existUrl = re.exec(err)[0];
          if (existUrl) {
            this.people.avatar = existUrl;
            this.$message({ message: "上传成功", type: "success" });
          } else {
            this.$message.error(err);
          }
        });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isJPG || isPNG) && isLt2M;
    },
    mavonImgAddFather(item) {
      return (pos, $file) => {
        const img = new FormData();
        img.append("file", $file);
        this.$axios
          .post("/picture/upload", img, {
            headers: {
              Authorization: this.$store.state.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.$refs[item][0].$imglst2Url([[pos, res.data.data]]);
          })
          .catch((err) => {
            var re = new RegExp(
              "(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]"
            );
            const existUrl = re.exec(err)[0];
            if (existUrl) {
              this.$refs[item][0].$imglst2Url([[pos, existUrl]]);
            } else {
              this.$message.error(err);
            }
          });
      };
    },
    mavonImgDel() {},
  },
  created() {
    if (this.id) {
      this.$axios.get("/people/" + this.id).then((res) => {
        this.loading = false;
        this.people = res.data.data;
        this.path[2].breadcrumbName = this.people.username;
        this.backupPeople = this.people;
      });
    } else {
      this.editMode = true;
      this.loading = false;
    }
  },
  data() {
    return {
      loading: true,
      path: [
        { path: "", breadcrumbName: this.$t("navigation.home") },
        { path: "people", breadcrumbName: this.$t("navigation.people") },
        { path: "peopledetail?id=" + this.id, breadcrumbName: "" },
      ],
      people: {
        id: null,
        username: "姓名",
        level: "professor",
        email: "example@mail.sysu.edu.cn",
        avatar: "https://sdfsdf.dev/216x240.png",
        introduction: "一段个人介绍",
        course: "",
        work: "",
        education: "",
        research: "",
        project: "",
        paper: "",
      },
      positions: [
        { value: "professor", label: "教授" },
        { value: "associateProfessor", label: "副教授" },
        { value: "bachelorStudent", label: "本科生" },
        { value: "masterStudent", label: "硕士生" },
        { value: "phdStudent", label: "博士生" },
        { value: "bachelor", label: "本科毕业生" },
        { value: "master", label: "硕士毕业生" },
        { value: "phd", label: "博士毕业生" },
      ],
      mavonImgAdd: {
        introduction: this.mavonImgAddFather("introduction"),
        course: this.mavonImgAddFather("course"),
        education: this.mavonImgAddFather("education"),
        research: this.mavonImgAddFather("research"),
        project: this.mavonImgAddFather("project"),
        paper: this.mavonImgAddFather("paper"),
      },
      backupPeople: {},
      editMode: false,
      commit: () => {
        this.$axios
          .post("/people/edit", this.people, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$alert("保存成功");
          });
      },
      deletePeople: () => {
        this.$axios
          .post("/people/delete/" + this.id, null, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$alert("删除成功");
            this.$router.push("/people");
          });
      },
    };
  },
};
</script>
<style scoped>
.people-info {
  display: inline-block;
  vertical-align: top;
  max-width: 300px;
  text-align: left;
  margin-right: 40px;
  margin-bottom: 60px;
}

.people-avatar {
  width: 216px;
  height: 240px;
  margin-bottom: 20px;
  margin-right: 40px;
}

.people-detail {
  display: inline-block;
  vertical-align: top;
  max-width: 720px;
  text-align: left;
}
</style>
