import VueI18n from "vue-i18n";
import en from "./en";
import zh from "./zh";
import dateTimeFormats from "./dateTimeFormats";
import Vue from "vue";
Vue.use(VueI18n);

export default new VueI18n({
  locale: "en",
  messages: {
    en,
    zh
  },
  dateTimeFormats
})