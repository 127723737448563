<template>
  <page-frame :header="path">
    <div class="project-main">
      <div v-if="loading" style="text-align: left">
        <el-skeleton animated :rows="10" style="margin-top: 40px" />
      </div>
      <div v-else>
        <div class="project-title" style="white-space: normal">
          {{ detail.title }}
        </div>
        <div>{{ $d(detail.created, "short") }}</div>
        <markdown-it-vue-light
          class="markdown-content"
          :content="detail.content"
          style="margin: 40px 0"
        />
      </div>
    </div>

    <el-button
      v-if="$store.state.token && !loading"
      @click="
        () => {
          this.editMode = !this.editMode;
        }
      "
    >
      {{ editMode ? "关闭编辑" : "打开编辑" }}
    </el-button>

    <!-- 编辑模块 -->
    <div
      v-if="$store.state.token && editMode"
      class="artical-editor"
      style="margin: 60px; text-align: left"
    >
      <div style="margin: 20px 0">
        <el-button @click="commitArtical">保存</el-button>
        <el-button v-if="detail.id" @click="deleteArtical">删除本文</el-button>
      </div>
      <div>
        文章标题：
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入标题"
          v-model="detail.title"
          style="margin-bottom: 20px"
        />
      </div>
      <div style="margin-bottom: 20px">
        发布时间：
        <el-date-picker
          v-model="detail.created"
          type="datetime"
          placeholder="选择日期时间"
          @change="hadleDateChange"
        />
      </div>
      <div>
        预览图链接：
        <el-input
          v-model="detail.avatar"
          placeholder="输入链接或点击下方按钮"
          style="max-width: 400px"
        />
      </div>
      <!-- :auto-upload="false" -->
      <el-upload
        class="avatar-uploader"
        action
        :http-request="uploadAvatar"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        style="margin-bottom: 20px"
      >
        <img
          v-if="detail.avatar"
          :src="detail.avatar"
          class="avatar"
          style="width: 200px; height: 140px"
        />
        <div v-else style="width: 200px; height: 140px; background-color: gray">
          <i
            class="el-icon-plus avatar-uploader-icon"
            style="font-size: 25px; margin-top: 60px"
          />
        </div>
      </el-upload>
      <div>摘要：</div>
      <div style="font-size: 12px">
        在这里填写的摘要不会显示在本页面，而是显示在外面列表页的卡片中。
      </div>
      <el-input
        type="textarea"
        :rows="3"
        placeholder="在这里填写的摘要不会显示在本页面，而是显示在外面列表页的卡片中。"
        v-model="detail.intro"
        style="margin-bottom: 20px"
      >
      </el-input>
      <div>正文：</div>
      <mavon-editor
        v-model="detail.content"
        ref="md"
        :subfield="false"
        @imgAdd="mavonImgAdd"
        @imgDel="mavonImgDel"
      />
    </div>
  </page-frame>
</template>
<script>
import PageFrame from "../components/PageFrame.vue";
import MarkdownItVueLight from "markdown-it-vue/dist/markdown-it-vue-light.umd.min.js";

export default {
  name: "projectDetail",
  components: { PageFrame, MarkdownItVueLight },
  props: { id: String },
  methods: {
    uploadAvatar(param) {
      const img = new FormData();
      img.append("file", param.file);
      this.$axios
        .post("/picture/upload", img, {
          headers: {
            Authorization: this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.detail.avatar = res.data.data;
          this.$message({ message: "上传成功", type: "success" });
        })
        .catch((err) => {
          var re = new RegExp(
            "(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]"
          );
          const existUrl = re.exec(err)[0];
          if (existUrl) {
            this.detail.avatar = existUrl;
            this.$message({ message: "上传成功", type: "success" });
          } else {
            this.$message.error(err);
          }
        });
    },
    mavonImgAdd(pos, $file) {
      const img = new FormData();
      img.append("file", $file);
      this.$axios
        .post("/picture/upload", img, {
          headers: {
            Authorization: this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$refs.md.$imglst2Url([[pos, res.data.data]]);
          this.$message({ message: "上传图片成功", type: "success" });
        })
        .catch((err) => {
          var re = new RegExp(
            "(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]"
          );
          const existUrl = re.exec(err)[0];
          if (existUrl) {
            this.$refs.md.$imglst2Url([[pos, existUrl]]);
            this.$message({ message: "上传图片成功", type: "success" });
          } else {
            this.$message.error(err);
          }
        });
    },
    mavonImgDel() {},
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isJPG || isPNG) && isLt2M;
    },
  },
  created() {
    if (this.id) {
      this.$axios.get("/projects/" + this.id).then((res) => {
        this.loading = false;
        this.detail = {
          ...res.data.data,
          created: new Date(res.data.data.created),
        };
        this.path[2].breadcrumbName = res.data.data.title;
        this.backupDetail = this.detail;
      });
    } else {
      this.editMode = true;
      this.loading = false;
    }
  },
  data() {
    return {
      hadleDateChange: () => {},
      commitArtical: () => {
        this.$axios
          .post(
            "/projects/edit",
            { ...this.detail, created: this.detail.created.getTime() },
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.$alert("保存成功");
          });
      },
      deleteArtical: () => {
        this.$axios
          .post("/projects/delete/" + this.id, null, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$alert("删除成功");
            this.$router.push("/projects");
          });
      },
      loading: true,
      editMode: false,
      path: [
        { path: "", breadcrumbName: this.$t("navigation.home") },
        { path: "projects", breadcrumbName: this.$t("navigation.projects") },
        {
          path: "projectdetail?id=" + this.id,
          breadcrumbName: "请输入标题",
        },
      ],
      detail: {
        title: "请输入标题",
        content: "请使用markdown语法",
        created: new Date(),
        id: null,
        intro: "文章的摘要，不会显示在本页面，而是显示在外面列表页的卡片中。",
        avatar: "https://sdfsdf.dev/200x140.png",
      },
      backupDetail: {},
    };
  },
};
</script>
<style scoped>
.project-main {
  margin: 20px 60px;
  display: block;
  text-align: left;
}
.project-title {
  font-size: 30px;
  margin: 10px 0;
}
.project-img {
  width: 100%;
  margin: 20px 0;
}
.project-part {
  margin-bottom: 60px;
}
.project-subtitle {
  margin: 20px 0;
  font-size: 30px;
}
</style>
