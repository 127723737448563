<template>
  <div class="page-content">
    <a-page-header
      v-if="routes"
      :breadcrumb="{ props: { routes } }"
      style="text-align: left; height: 40px"
    />
    <hr v-if="routes" style="margin: 0 20px" />
    <slot />
  </div>
</template>
<script>
import Vue from "vue";
import { PageHeader } from "ant-design-vue";
Vue.use(PageHeader);
export default {
  name: "PageFrame",
  props: ["header"],
  data() {
    return {
      routes: this.wo,
    };
  },
};
</script>
<style scoped>
.page-content {
  max-width: 1200px;
  min-height: calc(
    100vh - var(--navigation-bar-height) - var(--footer-min-height)
  );
  height: 100%;
  /* flex: none 1 800px; */
  margin: 0 auto;
  margin-top: var(--navigation-bar-height);
  padding-bottom: 50px;
  background: var(--element-bg-color);
  box-shadow: 0 2px 6px 0 black;
}
</style>