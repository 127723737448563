<template>
  <page-frame>
    <div class="not-found-main">404 Not Found</div>
  </page-frame>
</template>
<script>
import PageFrame from "../components/PageFrame.vue";
export default {
  components: { PageFrame },
};
</script>
