<template>
  <div class="footer-backgroud">
    <div class="footer-main">
      <img src="../assets/logo-sysu.svg" alt="SYSU Logo" class="footer-logo" />
      <div class="footer-info">
        <div style="font-size: 24px">{{ $t("footer.labName") }}</div>
        <div style="color: var(--secondary-text-color)">
          {{ $t("footer.schoolName") }}
        </div>
        <div class="footer-address">{{ $t("footer.universityName") }}</div>
        <div class="footer-address">{{ $t("footer.address") }}</div>
        <div class="footer-address">{{ $t("footer.postcode") }}</div>
      </div>
      <div class="footer-contact">
        <div><a-icon style="margin: 8px" type="home" />www.intsys-lab.com</div>
        <div><a-icon style="margin: 8px" type="mail" />admin@intsys-lab.com</div>
        <div>
          <a-icon style="margin: 8px" type="github" />@IntelligentSystemsLab
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Icon } from "ant-design-vue";
Vue.use(Icon);
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.footer-backgroud {
  min-height: var(--footer-min-height);
  background-color: var(--element-bg-color);
  box-shadow: 0 2px 6px 0 black;
  font-size: 16px;
}
.footer-main {
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
}
.footer-logo {
  margin: 20px;
  width: 120px;
  display: inline-block;
  vertical-align: middle;
}
.footer-info {
  margin: 20px;
  display: inline-block;
  vertical-align: middle;
}
.footer-address {
  color: var(--comment-text-color);
}
.footer-contact {
  margin: 20px;
  position: relative;
  right: 0;
  display: inline-block;
  vertical-align: middle;
}
</style>