<template>
  <el-skeleton :loading="loading" animated class="project-card">
    <template slot="template">
      <el-skeleton-item class="project-card-img" />
      <div class="project-card-text">
        <div><el-skeleton-item variant="text" style="width: 70px" /></div>
        <div><el-skeleton-item variant="text" style="width: 50px" /></div>
      </div>
    </template>
    <template>
      <a @click="handleClick">
        <img class="project-card-img" :src="info.avatar" :alt="info.title" />
        <div class="project-card-text" style="position: absolute; width: 100%">
          <div
            style="
              width: 80%;
              margin: 0 auto;
              font-weight: 600;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ info.title }}
          </div>
          <div>
            {{ info.created ? $d(new Date(info.created), "short") : "" }}
          </div>
        </div>
      </a>
    </template>
  </el-skeleton>
</template>
<script>
export default {
  name: "ProjectCard",
  props: ["info", "loading"],
  data() {
    return {
      handleClick: () => {
        this.$router.push("projectdetail?id=" + this.info.id);
      },
    };
  },
};
</script>
<style scoped>
.project-card {
  position: relative;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 black;
  width: 200px;
  height: 190px;
}
.project-card-img {
  border-radius: 20px 20px 0 0;
  width: 200px;
  height: 140px;
}
.project-card-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
</style>