<!-- <template>
  <a class="people-card" target="_blank">
    <div class="people-card-text">
      <div style="font-weight: 600" class="text-nowrap">
        {{ info.username }}
      </div>
    </div>
  </a>
</template>
<script>
// import Vue from "vue";
export default {
  name: "GraduatedCard",
  props: ["info"],
};
</script>
<style scoped>
.people-card {
  position: relative;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 black;
  width: 180px;
  height: 50px;
}
.people-card-text {
  position: absolute;
  bottom: 13px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}
.text-nowrap {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style> -->
<!-- <template>
  <a class="people-card" target="_blank">
    <div class="people-card-row">
      <div style="font-weight: 600" class="text-nowrap">{{ info.username }}</div>
    </div>
    <div class="people-card-row">
      <div class="text-nowrap">{{ getGraduationLabel(info.level) }}</div>
    </div>
    <div class="people-card-row">
      <div class="text-nowrap">{{ info.graduationTo }}</div>
    </div>
  </a>
</template>

<script>
export default {
  name: "GraduatedCard",
  props: ["info"],
  methods: {
    getGraduationLabel(level) {
      switch (level) {
        case "bachelor":
          return "本科毕业生";
        case "master":
          return "硕士毕业生";
        case "phd":
          return "博士毕业生";
        default:
          return "毕业生";
      }
    },
  },
};
</script>

<style scoped>
.people-card {
  display: inline-block;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 black;
  width: 180px;
  padding: 10px;
  text-align: center;
  margin: 10px;
  height: 200px; /* 调整为适合的高度 */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.people-card-row {
  margin-bottom: 5px;
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style> -->
<template>
  <a class="people-card" target="_blank">
    <div class="people-card-text">
      <div style="font-weight: 600" class="text-nowrap">
        {{ info.username }}
      </div>
      <div class="text-nowrap">
        {{ info.level }}
      </div>
      <div class="text-nowrap">
       {{ info.graduationTo }}
      </div>
    </div>
  </a>
</template>

<script>
// import Vue from "vue";
export default {
  name: "GraduatedCard",
  props: ["info"],
};
</script>

<style scoped>
.people-card {
  position: relative;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 black;
  width: 180px;
  height: 90px; /* Increase the height to accommodate three lines */
}
.people-card-text {
  position: absolute;
  bottom: 9px;
  /* left: 50%;
  transform: translate(-50%);
  text-align: center; */
  
  left: 0;
  right: 0;
  /* transform: translate(-50%); */
  text-align: center;
}
.text-nowrap {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>