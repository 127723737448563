<template>
  <a class="people-card" :href="info.homepage" target="_blank">
    <div class="people-card-text">
      <div class="text-nowrap" :title="info.username">
        {{ info.username }}
      </div>
      <div class="text-nowrap">
        {{ info.school ? info.school : "" }}
      </div>
    </div>
  </a>
</template>
<script>
// import Vue from "vue";
export default {
  name: "CollaboratorCard",
  props: ["info"],
};
</script>
<style scoped>
.people-card {
  position: relative;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 black;
  width: 180px;
  height: 50px;
}
.people-card-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* transform: translate(-50%); */
  text-align: center;
}
.text-nowrap {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
