<template>
  <el-skeleton :loading="loading" animated>
    <template slot="template">
      <div class="artical-card-main">
        <div style="width: 220px" v-if="$store.state.screenSize == 'lg'">
          <el-skeleton-item class="artical-card-image" variant="image" />
        </div>
        <div class="artical-card-text" style="margin: 0 20px">
          <el-skeleton-item
            variant="text"
            class="artical-card-title"
            style="width: 50%"
          />
          <div class="artical-card-abstract">
            <el-skeleton-item variant="text" />
            <el-skeleton-item variant="text" />
            <el-skeleton-item variant="text" />
          </div>
          <el-skeleton-item
            variant="text"
            class="artical-card-time"
            style="width: 20%"
          />
        </div>
        <a-button v-if="$store.state.screenSize == 'lg'" class="detail-btn">
          {{ $t("card.detail") }}
        </a-button>
      </div>
    </template>
    <template>
      <a class="artical-card-main" @click="handleClick">
        <div style="width: 220px" v-if="$store.state.screenSize == 'lg'">
          <img
            class="artical-card-image"
            :src="info.avatar"
            alt="preview image"
          />
        </div>
        <div class="artical-card-text" style="margin: 0 20px">
          <div class="artical-card-title">{{ info.title }}</div>
          <div class="artical-card-abstract">{{ info.intro }}</div>
          <div class="artical-card-time">
            {{ info.created ? $d(new Date(info.created), "short") : "" }}
          </div>
        </div>
        <a-button v-if="$store.state.screenSize == 'lg'" class="detail-btn">
          {{ $t("card.detail") }}
        </a-button>
      </a>
    </template>
  </el-skeleton>
</template>
<script lang="ts">
import Vue from "vue";

import { Button } from "ant-design-vue";
Vue.use(Button);

export default {
  name: "ArticalCard",
  props: ["info", "loading"],
  data() {
    return {
      handleClick: () => {
        this.$router.push(this.info.type + "detail?id=" + this.info.id);
      },
    };
  },
};
</script>
<style scoped>
.artical-card-main {
  height: 180px;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0);
  background-color: var(--element-bg-color);
}
.artical-card-image {
  width: 200px;
  height: 140px;
  margin-left: 20px;
}
.artical-card-text {
  position: relative;
  height: 100%;
  flex: 1 1 800px;
  text-align: left;
}
.artical-card-title {
  position: relative;
  margin: 10px 0;
  font-size: 18px;
}
.artical-card-time {
  position: absolute;
  bottom: 10px;
}
.artical-card-abstract,
.artical-card-time {
  font-size: 16px;
  color: var(--secondary-text-color);
}

.artical-card-title,
.artical-card-time {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.artical-card-abstract {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-btn {
  flex: 1 1 100px;
  margin-right: 20px;
}
</style>
