<template>
  <div id="app">
    <!-- <img src="./assets/logo.png" /> -->
    <NavigationBar />
    <router-view />
    <PageFooter />
    <mavon-editor hidden="true" />
  </div>
</template>

<script>
import Vue from "vue";
import NavigationBar from "./components/NavigationBar.vue";
import PageFooter from "./components/PageFooter.vue";
import "./style/katex-patch.css";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
Vue.use(mavonEditor);

export default {
  components: { NavigationBar, PageFooter },
  name: "App",
  created() {
    this.$store.commit("changeScreen", document.body.clientWidth);
    window.onresize = () => {
      this.$store.commit("changeScreen", document.body.clientWidth);
    };
  },
  data() {
    return {
      clientScreen: "",
    };
  },
};
</script>

<style>
:root {
  --main-background-color: #455a64;
  --element-bg-color: #263238;
  --primary-text-color: #cfd8dc;
  --secondary-text-color: #90a4ae;
  --comment-text-color: #607d8b;
  --navigation-bar-height: 60px;
  --footer-min-height: 240px;
}
#app {
  min-width: 350px;
  overflow-x: auto;
  /* height: 100vh; */
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--main-background-color);
  color: var(--primary-text-color);
}
hr {
  border: none;
  border-top: 2px solid;
  border-color: var(--secondary-text-color);
}
.title-md {
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content p,
.markdown-content ul,
.markdown-content li {
  color: var(--primary-text-color);
}
.markdown-content img {
  max-width: 700px;
}
</style>
