<template>
    <page-frame :header="path">
      <el-skeleton :loading="loading" animated style="margin: 20px 40px">
        <template slot="template">
          
          
          <artical-card
            v-for="(_, i) in Array(5)"
            :key="i - 5"
            :info="{}"
            :loading="true"
            style="margin-bottom: 20px"
          />
        </template>
        <template>
          <div class="title-md">
            {{ $t('card.representwork') }} — <a href="/#/peopledetail?id=11">{{ $t('card.personnal') }}</a>
          </div>
          <!-- <div class="title-md">
           代表作——<a href="/#/peopledetail?id=11">个人主页</a>
          </div> -->
          <!-- <div class="title-md">
            代表作
          </div> -->
          <div
            v-for="worksList in worksByID"
            :key="worksList[0].id"
            style="margin-bottom: 40px"
          >
            
            <artical-card
              v-for="info in worksList"
              :info="info"
              :key="info.id"
              :loading="false"
              style="margin-bottom: 20px"
            />
          </div>
        </template>
      </el-skeleton>
      <el-button
        v-if="$store.state.token"
        type="primary"
        icon="el-icon-edit"
        circle
        style="
          position: fixed;
          right: 100px;
          bottom: 100px;
          width: 60px;
          height: 60px;
        "
      />
    </page-frame>
  </template>
  <script>
  import ArticalCard from "../components/ArticalCard.vue";
  import PageFrame from "../components/PageFrame.vue";
  
  export default {
    name: "worksPage",
    components: { ArticalCard, PageFrame },
    created() {
      const worklist=[]
      const fetchPublication = (id, work_no) => {
        return this.$axios.get(`/publications/${id}`)
          .then((res) => {
            this.loading = false;
            const x = res.data.data;
            const publicationItem = {
              ...x,
              time: new Date(x.created),
              type: "publication",
              work_no: work_no,
            };

            worklist.push([publicationItem]);
          });
      };

      // 发起多个请求，每个请求都包含 ID 和 work_no
      const requests = [
        fetchPublication(48, 1),
        fetchPublication(35, 2),
        fetchPublication(32, 3),
        fetchPublication(50, 4),
        fetchPublication(45, 5),
      ];

      Promise.all(requests)
    .then(() => {
      worklist.sort((a, b) => (a[0].work_no > b[0].work_no ? 1 : -1));

      if (worklist.length > 0)
        this.worksByID.push([worklist[0][0]]);
      for (let i = 1; i < worklist.length; i++) {
        this.worksByID.push([worklist[i][0]]);
      }
    })
    .catch((error) => {
      console.error("Error fetching publications:", error);
    });
},
   data() {
      return {
        path: [
          { path: "", breadcrumbName: this.$t("navigation.home") },
          {
            path: "representativepage",
            breadcrumbName: this.$t("navigation.representativepage"),
          },
        ],
        loading: true,
        worksByID:[]
        
      };
    },
  };
  </script>
  