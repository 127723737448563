<template>
  <page-frame>
    <div class="home-main">
      <div style="text-align: left">
        <div class="home-block" style="max-width: 400px; margin: 40px">
          <div class="title-md">{{ $t("home.introduction") }}</div>
          <div>
            {{ $t("home.introductionText") }}
            <br>
            <br>
            <span style="font-style: italic; font-weight: bold; color: #FFFF00;">{{ $t("home.recruit") }}</span>

          </div>
        </div>

        <!-- 合作方 -->
        <div class="home-block" style="max-width: 600px; margin: 40px">
          <div class="title-md">{{ $t("home.collaborator") }}</div>
          <div>
            <img
              class="collab-img"
              v-for="item in collaborators"
              :src="item.imgUrl"
              :alt="item.name"
              :key="item.id"
            />
          </div>
        </div>
      </div>
      <div style="text-align: left; margin: 40px">
        <div class="title-md" style="position: relative">
          {{ $t("home.projects") }}
          <a
            @click="$router.push('projects')"
            style="
              position: absolute;
              right: 0;
              bottom: 0;
              font-size: 18px;
              font-weight: 400;
              vertical-align: baseline;
            "
          >
            {{ $t("home.more") }}
            <a-icon
              type="right-circle"
              style="font-size: 20px; margin-left: 10px"
            />
          </a>
        </div>
        <div class="scroll">
          <project-card
            v-for="p in projectsList"
            :key="p.id"
            :info="p"
            :loading="projectsLoading"
            style="margin-right: 20px; text-align: center"
          />
        </div>
      </div>

      <!-- 最新的论文 -->
      <div style="text-align: left">
        <el-skeleton
          :loading="publicationsLoading"
          animated
          class="home-block"
          style="max-width: 400px"
        >
          <template slot="template">
            <div class="title-md">{{ $t("home.publications") }}</div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div class="arrow-before text-item" style="text-align: right">
              <a @click="$router.push('publications')">
                {{ $t("home.allPublications") }}
              </a>
            </div>
          </template>
          <template>
            <div class="title-md">{{ $t("home.publications") }}</div>
            <div v-for="item in publications" :key="item.id" class="text-item">
              <a
                class="arrow-before"
                @click="$router.push('publicationdetail?id=' + item.id)"
              >
                {{ item.title }}
              </a>
              <div style="color: var(--secondary-text-color)">
                {{ item.author }}
              </div>
              <div style="color: var(--comment-text-color)">
                {{ item.journal }}
              </div>
            </div>
            <div class="arrow-before text-item" style="text-align: right">
              <a @click="$router.push('publications')">
                {{ $t("home.allPublications") }}
              </a>
            </div>
          </template>
        </el-skeleton>

        <!-- 新闻 -->
        <el-skeleton
            :loading="newsLoading"
            animated
            class="home-block"
            style="max-width: 300px"
        >
          <template slot="template">
            <div class="title-md">{{ $t("home.news") }}</div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div class="arrow-before text-item" style="text-align: right">
              <a @click="$router.push('news')">
                {{ $t("home.allNews") }}
              </a>
            </div>
          </template>
          <!--   TODO:新闻push      -->
          <template>
            <div class="title-md">{{ $t("home.news") }}</div>
            <div v-for="item in news" :key="item.id" class="text-item">
              <a
                  class="arrow-before"
                  @click="$router.push('newsdetail?id=' + item.id)"
              >
                {{ item.title }}
              </a>
              <div style="color: var(--secondary-text-color)">
                {{ $d(new Date(item.created), "short")}}
              </div>


            </div>
            <div class="arrow-before text-item" style="text-align: right">
              <a @click="$router.push('news')">
                {{ $t("home.allNews") }}
              </a>
            </div>
          </template>
        </el-skeleton>


        <!-- 研究者 -->
        <el-skeleton
          :loading="researchersLoading"
          animated
          class="home-block"
          style="max-width: 300px"
        >
          <template slot="template">
            <div class="title-md">{{ $t("home.researchers") }}</div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div><el-skeleton-item variant="text" style="width: 300px" /></div>
            <div class="arrow-before text-item" style="text-align: right">
              <a @click="$router.push('people')">
                {{ $t("home.allResearchers") }}
              </a>
            </div>
          </template>
          <template>
            <div class="title-md">{{ $t("home.researchers") }}</div>
            <div v-for="p in researchers" :key="p.id" class="text-item">
              <a
                class="arrow-before"
                @click="$router.push('peopledetail?id=' + p.id)"
              >
                {{ p.username }}
              </a>
              <div style="color: var(--secondary-text-color)">
                {{ $t("people." + p.level) }}
              </div>
              <div style="color: var(--comment-text-color)">
                {{ p.field }}
              </div>
            </div>
            <div class="arrow-before text-item" style="text-align: right">
              <a @click="$router.push('people')">
                {{ $t("home.allResearchers") }}
              </a>
            </div>
          </template>
        </el-skeleton>
      </div>
    </div>
  </page-frame>
</template>
<script>
import PageFrame from "../components/PageFrame.vue";
import ProjectCard from "../components/ProjectCard.vue";
import { Icon } from "ant-design-vue";
import Vue from "vue";
Vue.use(Icon);

export default {
  name: "HomePage",
  components: { PageFrame, ProjectCard },
  created() {
    this.$axios.get("/projects/").then((res) => {
      this.projectsLoading = false;
      this.projectsList = res.data.data.records.sort((a, b) =>
        a.created < b.created ? 1 : -1
      );
    });
    this.$axios.get("/publications/").then((res) => {
      this.publicationsLoading = false;
      this.publications = res.data.data.records
        .sort((a, b) => (a.created < b.created ? 1 : -1))
        .slice(0, 3);
    });
    const levelWeight = {
      professor: 0,
      associateProfessor: 1,
      assistantProfessor: 2,
      phdStudent: 3,
      masterStudent: 4,
      bachelorStudent: 5,
      phd: 6,
      master: 7,
      bachelor: 8,
    };
    this.$axios.get("/people/").then((res) => {
      this.researchersLoading = false;
      this.researchers = res.data.data.records
        .sort((a, b) =>
          levelWeight[a.level] > levelWeight[b.level]
            ? 1
            : a.level == b.level
            ? 0
            : -1
        )
        .slice(0, 7);
    });
    this.$axios.get("/news/").then((res) => {
      this.newsLoading = false;
      this.news = res.data.data.records
          .sort((a, b) =>
              levelWeight[a.level] > levelWeight[b.level]
                  ? 1
                  : a.level == b.level
                  ? 0
                  : -1
          )
          .slice(0, 7);
    });
  },
  data() {
    return {
      projectsLoading: true,
      publicationsLoading: true,
      researchersLoading: true,
      newsLoading: true,
      projectsList: Array(10)
        .fill(null)
        .map((_, i) => {
          return { id: i - 10 };
        }),
      collaborators: [
        {
          name: "RMIT",
          imgUrl: "https://s2.loli.net/2023/04/25/c7NybhLCkemrfvF.jpg",
        },
        {
          name: "PKU",
          imgUrl: "https://s2.loli.net/2022/06/13/lexLXQMEfd8HcRU.png",
        },
        {
          name: "TJU",
          imgUrl: "https://s2.loli.net/2022/06/13/V8lUZMC5ogHA1pz.png",
        },
        {
          name: "HIT",
          imgUrl: "https://s2.loli.net/2022/06/13/BEV7vahZzDCp2bU.png",
        },
        {
          name: "XMU",
          imgUrl: "https://s2.loli.net/2022/12/06/Ja6XzSpVnHfwK8i.png",
        },
        {
          name: "JLU",
          imgUrl: "https://s2.loli.net/2022/06/13/Ue2NIR5O8fDL7Bt.png",
        },
        {
          name: "FZU",
          imgUrl: "https://s2.loli.net/2022/06/13/MFvUcGhqnZbY8Jd.png",
        },
        {
          name: "HKPU",
          imgUrl: "https://s2.loli.net/2022/06/13/AIhZ7f5tcQL3SYd.png",
        },
        {
          name: "CNR",
          imgUrl: "https://s2.loli.net/2023/04/24/MeFcGx5oatgf2Dm.png",
        },
        {
          name: "NTU",
          imgUrl: "https://s2.loli.net/2022/06/13/s6SZaETmczFxeo7.png",
        },
        {
          name: "SUTD",
          imgUrl: "https://s2.loli.net/2022/06/13/cnd2Fk7sSXAPWCb.png",
        },
        {
          name: "A*Star",
          imgUrl: "https://s2.loli.net/2022/12/06/IHj6VscrN4zWux7.jpg",
        },
        {
          name: "ETH",
          imgUrl: "https://s2.loli.net/2022/06/13/oimJL4edwOhxSMz.png",
        },

        {
          name: "MIT",
          imgUrl: "https://s2.loli.net/2022/06/13/mvXNhdSeFs7rDqL.png",
        },
        {
          name: "Princeton",
          imgUrl: "https://s2.loli.net/2022/06/13/3ZbwEC4H5VYAiFd.png",
        },
      ],
      publications: Array(3)
        .fill(null)
        .map((_, i) => {
          return { id: i - 3 };
        }),
      positions: Array(3)
        .fill(null)
        .map((_, i) => {
          return { id: i - 3 };
        }),
      researchers: Array(3)
        .fill(null)
        .map((_, i) => {
          return { id: i };
        }),
      news: Array(3)
          .fill(null)
          .map((_, i) => {
            return { id: i };
          }),
    };
  },
};
</script>
<style scoped>
.home-main {
  display: block;
}
.home-block {
  vertical-align: top;
  display: inline-block;
  margin-left: 40px;
  /* max-width: 400px; */
  text-align: left;
}
.collab-img {
  margin: 10px;
  width: 100px;
  height: 100px;
}
.text-item {
  margin: 10px 40px;
}
.arrow-before::before {
  position: absolute;
  margin-left: -30px;
  content: "\27F6";
  font-size: 16px;
  font-weight: 600;
}
.scroll {
  padding: 10px;
  white-space: nowrap;
  overflow-x: scroll;
}
.scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.scroll::-webkit-scrollbar-button {
  width: 16px;
  height: 16px;
}
.scroll::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, 0.5);
  border: 9px none white;
  border-radius: 50px;
}
.scroll::-webkit-scrollbar-thumb:hover,
.scroll::-webkit-scrollbar-thumb:active {
  background: rgba(150, 150, 150, 0.8);
}
.scroll::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none white;
  border-radius: 36px;
}
.scroll::-webkit-scrollbar-track:hover,
.scroll::-webkit-scrollbar-track:active {
  background: transparent;
}
.scroll::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
