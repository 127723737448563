<template>
  <a @click="handleClick">
    <el-skeleton :loading="loading" animated class="people-card">
      <template slot="template">
        <el-skeleton-item class="people-card-image" />
        <div class="people-card-text">
          <div><el-skeleton-item variant="text" style="width: 70px" /></div>
          <div><el-skeleton-item variant="text" style="width: 50px" /></div>
        </div>
      </template>
      <template>
        <img class="people-card-image" :src="info.avatar" alt="preview image" />
        <div class="people-card-text">
          <div style="font-weight: 600" class="text-nowrap">
            {{ info.username }}
          </div>
          <div class="text-nowrap">
            {{ info.level ? $t("people." + info.level) : "" }}
          </div>
        </div>
      </template>
    </el-skeleton>
  </a>
</template>
<script>
// import Vue from "vue";
export default {
  name: "PeopleCard",
  props: ["info", "loading"],
  methods: {
    handleClick() {
      if (this.info.id) this.$router.push(`peopledetail?id=${this.info.id}`);
    },
  },
};
</script>
<style scoped>
.people-card {
  position: relative;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 black;
  width: 180px;
  height: 250px;
}
.people-card-image {
  border-radius: 20px 20px 0 0;
  width: 180px;
  height: 200px;
}
.people-card-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}
.text-nowrap {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
