<template>
  <page-frame :header="path">
    <el-skeleton style="margin: 20px 40px" :loading="loading">
      <template slot="template">
        <div
          v-for="(position, i) in ['staff', 'students', 'graduates']"
          :key="i - 3"
        >
          <div class="title-md">{{ $t("people." + position) }}</div>
          <div style="text-align: left">
            <people-card
              class="card"
              v-for="(_, j) in Array(7)"
              :info="{}"
              :loading="true"
              :key="j - 7"
            />
          </div>
        </div>
      </template>
      <template>
        <!-- 教师 -->
        <div v-if="staffList.length">
          <div class="title-md">{{ $t("people.staff") }}</div>
          <div style="text-align: left">
            <people-card
              v-for="info in staffList"
              :info="info"
              :loading="false"
              :key="info.id"
              class="card"
            />
          </div>
        </div>

        <!-- 合作者 -->
        <!-- <div v-if="collaboratorList.length">
          <div class="title-md">{{ $t("people.collaborators") }}</div>
          <div style="text-align: left">
            <collaborator-card
              v-for="info in collaboratorList"
              :key="info.id"
              class="collaborator-card"
              :info="info"
            />
          </div>
        </div> -->
        <!-- <div>
          <div @click="toggleCollaborators" class="title-md">
            {{ $t("people.collaborators") }}
            <i class="el-icon-arrow-down" :class="isOpen ? 'open' : ''"></i>
          </div>
          <transition name="fade">
            <div v-show="isOpen">
              <div style="text-align: left">
                <collaborator-card
                  v-for="info in collaboratorList"
                  :key="info.id"
                  class="collaborator-card"
                  :info="info"
                />
              </div>
            </div>
          </transition>
        </div> -->
        <div>
          <div class="title-md">
            {{ $t("people.collaborators") }}
          </div>
          <transition name="fade">
              <div style="text-align: left">
                <collaborator-card
                  v-for="info in collaboratorList"
                  :key="info.id"
                  class="collaborator-card"
                  :info="info"
                />
            </div>
          </transition>
        </div>

        <!-- 本科生 -->
        <div v-if="phdStudentList.length">
          <div class="title-md">{{ $t("people.phdStudent") }}</div>
          <div style="text-align: left">
            <people-card
              v-for="info in phdStudentList"
              :info="info"
              :loading="false"
              :key="info.id"
              class="card"
            />
          </div>
        </div>

        <!-- 本科生 -->
        <div v-if="masterStudentList.length">
          <div class="title-md">{{ $t("people.masterStudent") }}</div>
          <div style="text-align: left">
            <people-card
              v-for="info in masterStudentList"
              :info="info"
              :loading="false"
              :key="info.id"
              class="card"
            />
          </div>
        </div>

        <!-- 本科生 -->
        <div v-if="bachelorStudentList.length">
          <div class="title-md">{{ $t("people.bachelorStudent") }}</div>
          <div style="text-align: left">
            <people-card
              v-for="info in bachelorStudentList"
              :info="info"
              :loading="false"
              :key="info.id"
              class="card"
            />
          </div>
        </div>

        <!-- 毕业生 -->
        <!-- <div v-if="graduateList.length">
          <div class="title-md">{{ $t("people.graduates") }}</div>
          <div style="text-align: left">
            <graduated-card
              v-for="info in graduateList"
              :info="info"
              :loading="false"
              :key="info.id"
              class="card"
            />
          </div> 
        </div>
         -->
         <!-- <div v-if="graduateList.length">
          <div class="title-md">{{ $t("people.graduates") }}</div>
          <div style="text-align: left">
            <graduated-card
              v-for="info in graduateList"
              :key="info.id"
              :info="info"
              class="card"
            />
          </div>
        </div> -->
        <div v-if="Object.keys(graduatesGroupedByYear).length">
          <div class="title-md">{{ $t("people.graduates") }}</div>
          <div v-for="year in [2024, 2023, 2022]" :key="year" >
            <div class="title-sm">{{ year }}届</div>
            <div style="text-align: left">
              <graduated-card v-for="info in graduatesGroupedByYear[year]" :key="info.id" :info="info" class="card" /> 
            </div>
          </div>
        </div>
      </template>
    </el-skeleton>
    <el-button
      v-if="$store.state.token"
      type="primary"
      icon="el-icon-edit"
      circle
      @click="newPeople"
      style="
        position: fixed;
        right: 100px;
        bottom: 100px;
        width: 60px;
        height: 60px;
      "
    />
  </page-frame>
</template>
<script>
import PageFrame from "../components/PageFrame.vue";
import PeopleCard from "../components/PeopleCard.vue";
import CollaboratorCard from "../components/CollaboratorCard.vue";
import GraduatedCard from "../components/GraduatedCard";
// import { Tooltip } from 'element-ui';

export default {
  name: "PeoplePage",
  components: { PageFrame, PeopleCard, CollaboratorCard, GraduatedCard},
  created() {
    this.$axios.get("/people/").then((res) => {
      this.loading = false;
      console.log(res.data.data.records);
      res.data.data.records.forEach((people) => {
        if (
          people.level == "professor" ||
          people.level == "associateProfessor"
        ) {
          this.staffList.push(people);
        } else if (people.level == "bachelorStudent") {
          this.bachelorStudentList.push(people);
        } else if (people.level == "masterStudent") {
          this.masterStudentList.push(people);
        } else if (people.level == "phdStudent") {
          this.phdStudentList.push(people);
        // } else if (
          // people.level == "bachelor" ||
        //   people.level == "master" ||
        //   people.level == "phd"
        // ) {
        //   this.graduateList.push(people);
        } else {
          console.error("People position not found! Check people.level ");
        }
      });
      
      this.graduateList.forEach((graduate) => {
        if (!this.graduatesGroupedByYear[graduate.year]) {
          this.graduatesGroupedByYear[graduate.year] = [];
        }
        this.graduatesGroupedByYear[graduate.year].push(graduate);
      });

      // Sort
      // this.staffList = this.staffList.sort((a, b) => {
      //   return a.level == "professor" ? -1 : b.level == "professor" ? 1 : 0;
      // });
      // const graduateLevel = {
      //   bachelor: 0,
      //   master: 1,
      //   phd: 2,
      // };
      // this.graduateList = this.graduateList.sort((a, b) => {
      //   return graduateLevel[a.level] > graduateLevel[b.level]
      //     ? -1
      //     : a.level == b.level
      //     ? 0
      //     : 1;
      // });
    //   this.graduateList = this.graduateList.sort((a, b) => {
    //   if (a.graduationYear !== b.graduationYear) {
    //     return b.graduationYear - a.graduationYear;
    //   }
    //   return graduateLevel[b.level]-graduateLevel[a.level]
    //       ? -1
    //       : a.level == b.level
    //       ? 0
    //       : 1;
    //   });
    });
  },
  data() {
    return {
      path: [
        { path: "", breadcrumbName: this.$t("navigation.home") },
        { path: "people", breadcrumbName: this.$t("navigation.people") },
      ],
      loading: true,
      staffList: [],
      bachelorStudentList: [],
      masterStudentList: [],
      phdStudentList: [],
      graduateList: [
        {
          username:"贺俊姝",
          year:2023,
          level:"硕士毕业生",
          graduationTo:"江西省委组织部选调",
        },
        {
          username:"简伟涛",
          year:2024,
          level:"博士毕业生",
          graduationTo:"-",
        },
        {
          username:"刘晟",
          year:2024,
          level:"硕士毕业生",
          graduationTo:"皇家理工学院读博",
        },
        {
          username:"章圣律",
          year:2024,
          level:"硕士毕业生",
          graduationTo:"自由职业",
        },
        {
          username:"方明辉",
          year:2024,
          level:"硕士毕业生",
          graduationTo:"中国移动",
        },
        {
          username:"张翼翾",
          year:2024,
          level:"本科毕业生",
          graduationTo:"香港科技大学读研",
        },
        {
          username:"杨许",
          year:2024,
          level:"本科毕业生",
          graduationTo:"中山大学读研",
        },
        {
          username:"郑金涛",
          year:2024,
          level:"本科毕业生",
          graduationTo:"中山大学读研",
        },
        {
          username:"吴承瀚",
          year:2023,
          level:"本科毕业生",
          graduationTo:"香港中文大学读研",
        },
        {
          username:"侯英威",
          year:2023,
          level:"本科毕业生",
          graduationTo:"中山大学读研",
        },
        {
          username:"林俊龙",
          year:2023,
          level:"本科毕业生",
          graduationTo:"上海交通大学读研",
        },
        {
          username:"李浩源",
          year:2023,
          level:"本科毕业生",
          graduationTo:"中山大学读研",
        },
        {
          username:"李宏立",
          year:2023,
          level:"本科毕业生",
          graduationTo:"中山大学读研",
        },
        {
          username:"艾子翔",
          year:2023,
          level:"本科毕业生",
          graduationTo:"中山大学读研",
        },
        {
          username:"何家琪",
          year:2022,
          level:"本科毕业生",
          graduationTo:"TP-LINK",
        }
      ],
      newPeople: () => {
        this.$router.push("peopledetail");
      },
      graduatesGroupedByYear: {},
      isOpen: false,
      collaboratorList: [
        {
          name: "Moshe Ben-Akiva",
          school: "MIT",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://cee.mit.edu/people_individual/moshe-e-ben-akiva/",
        },
        {
          name: "Carlo Ratti",
          school: "MIT",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://dusp.mit.edu/people/carlo-ratti",
        },
        {
          name: "H.Vincent Poor",
          school: "Princeton",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://engineering.princeton.edu/faculty/h-vincent-poor",
        },
        {
          name: "Dusit Niyato",
          school: "NTU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://personal.ntu.edu.sg/dniyato/",
        },
        {
          name: "Bingran Zuo",
          school: "NTU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage:
            "https://www.ntu.edu.sg/rris/about-us/our-people/researcher-staff/dr-zuo-bingran",
        },
        {
          name: "Chau Yuen",
          school: "NTU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://dr.ntu.edu.sg/cris/rp/rp02157",
        },
        {
          name: "Bige Tuncer",
          school: "SUTD",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://asd.sutd.edu.sg/people/faculty/bige-tuncer",
        },
        {
          name: "Paolo Santi",
          school: "MIT and IIT-CNR",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "http://webhost.services.iit.cnr.it/staff/paolo.santi/index.php",
        },
        {
          name: "赵娟娟",
          school: "CNU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://cret.cnu.edu.cn/szdw/js1/189591.htm",
        },
        {
          name: "陈语谦",
          school: "PKU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://www.ece.pku.edu.cn/info/1053/2659.htm",
        },
        {
          name: "张洪谋",
          school: "PKU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://www.sg.pku.edu.cn/szdw/zzjs/csyqyglx1/1339592.htm",
        },
        {
          name: "曹哲静",
          school: "TJU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage:
            "https://upd-caup.tongji.edu.cn/c8/8d/c20527a247949/page.htm",
        },
        {
          name: "陈志聪",
          school: "FZU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage:
            "http://itlab.fzu.edu.cn/gzl/ZhuanJi/TeacherInfo2.aspx?No=T14002",
        },
        {
          name: "朱瑞",
          school: "A*Star",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage:
            "https://felix-rz.github.io/",
        },
        {
          name: "程绍武",
          school: "HIT",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "http://homepage.hit.edu.cn/chengshaowu",
        },
        {
          name: "孔卉",
          school: "XMU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "https://archt.xmu.edu.cn/info/1095/7023.htm",
        },
        {
          name: "常毅",
          school: "JLU",
          avatar: "https://sdfsdf.dev/216x240.png",
          homepage: "http://icfs.jlu.edu.cn/info/1041/1099.htm",
        },
      ].map((info) => {
        return {
          ...info,
          username: info.name,
          id: Math.random(),
        };
      }),
    };
  },
  methods: {
    toggleCollaborators() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style scoped>
.card {
  display: inline-block;
  margin: 0 20px 40px 20px;
}
.collaborator-card {
  display: inline-block;
  margin: 0 20px 20px 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.title-md {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.title-sm {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 18px;
}
.open {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}
</style>
