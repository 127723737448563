<template>
  <page-frame :header="path">
    <el-skeleton :loading="loading" animated style="margin: 20px 40px">
      <template slot="template">
        <div class="title-md">
          <el-skeleton-item variant="text" style="width: 10%" />
        </div>
        <artical-card
          v-for="(_, i) in Array(5)"
          :key="i - 5"
          :info="{}"
          :loading="true"
          style="margin-bottom: 20px"
        />
      </template>
      <template>
        <div
          v-for="newsList in newsByYears"
          :key="newsList[0].time.getYear()"
          style="margin-bottom: 40px"
        >
          <div class="title-md" :id="newsList[0].time.getFullYear()">
            {{ newsList[0].time.getFullYear() }}
          </div>
          <artical-card
            v-for="info in newsList"
            :info="info"
            :key="info.id"
            :loading="false"
            style="margin-bottom: 20px"
          />
        </div>
      </template>
    </el-skeleton>
    <el-button
      v-if="$store.state.token"
      type="primary"
      icon="el-icon-edit"
      circle
      @click="newArtical"
      style="
        position: fixed;
        right: 100px;
        bottom: 100px;
        width: 60px;
        height: 60px;
      "
    />
  </page-frame>
</template>
<script>
import ArticalCard from "../components/ArticalCard.vue";
import PageFrame from "../components/PageFrame.vue";

export default {
  name: "NewsPage",
  components: { ArticalCard, PageFrame },
  created() {
    this.$axios.get("/news/").then((res) => {
      this.loading = false;
      const newsList = res.data.data.records
        .map((x) => {
          return {
            ...x,
            time: new Date(x.created),
            type: "news",
          };
        })
        .sort((a, b) => (a.time < b.time ? 1 : -1));

      if (newsList.length > 0) this.newsByYears.push([newsList[0]]);
      for (let i = 1; i < newsList.length; i++) {
        if (newsList[i - 1].time.getYear() == newsList[i].time.getYear()) {
          this.newsByYears[this.newsByYears.length - 1].push(newsList[i]);
        } else {
          this.newsByYears.push([newsList[i]]);
        }
      }
    });
  },
  data() {
    return {
      path: [
        { path: "", breadcrumbName: this.$t("navigation.home") },
        { path: "news", breadcrumbName: this.$t("navigation.news") },
      ],
      loading: true,
      newsByYears: [],
      newArtical: () => {
        this.$router.push("newsdetail");
      },
    };
  },
};
</script>