export default {
  navigation: {
    labName: "Intelligent Systems Lab",
    home: "Home",
    changeLanguage: "中文",
    people: "People",
    news: "News",
    projects: "Projects",
    publications: "Publications",
    index: "Home",
    representativepage:"Representative-Works"
  },
  home: {
    introduction: "Intro",
    recruit:"We welcome students who are interested in Smart City, Internet of Things, Federated Computing, Autonomous Systems, Artificial Intelligence, LLMs, etc. to bail out and apply for Master's and PhD programs. Meanwhile, the lab is always recruiting postdoctoral fellows as well as full-time researchers, welcome to write to us for inquiries.",
    introductionText: `
    Intelligent systems emphasize the integration of information and the physical world. By sensing information changes in the physical world, learning the development and operational rules of things, formulating optimized and balanced resource coordination plans, responding to diverse and changing personalized demands, the overall autonomous service and self-organizing operational capabilities of the system are continuously improved, transforming from isolated monolithic intelligence to collaborative group intelligence. 
    This laboratory is based on the School of Intelligent Systems Engineering of Sun Yat-sen University, and cooperates with domestic and foreign universities and research institutions to research the basic theories and technical methods of group federated computing for new smart cities, and carry out application demonstrations in autonomous transportation, smart healthcare, new energy, and other fields.`,
    collaborator: "Collaborator",
    projects: "Projects",
    publications: "Publications",
    news: "News",
    positions: "Positions",
    applyHere: "Apply Here",
    researchers: "Researchers",
    more: "More",
    allPublications: "All Publications",
    allResearchers: "All Researchers",
    allNews: "All News"
  },
  footer: {
    labName: "Intelligent Systems Laboratory",
    schoolName: "School of Intelligent Systems Engineering",
    universityName: "Sun Yat-Sen University",
    address: "No. 66 Gongchang Road, Xinhu Street, Guangming District, Shenzhen, Guangdong",
    postcode: "518107, P. R. China"
  },
  card: {
    detail: "Detail",
    representwork: "Representative Works",
    personnal: "Personal Page",
  },
  people: {
    staff: "Academic Staff",
    students: "Research Students",
    graduates: "Alumni",
    collaborators: "Collaborators",
    introduction: "Biography",
    course: "Courses taught",
    work: "Work experience",
    education: "Education",
    research: "Research interests",
    project: "Current research",
    paper: "Selected publication",
    professor: "Professor",
    associateProfessor: "Associate Professor",
    assistantProfessor: "Assistant Professor",
    bachelorStudent: "Undergraduate",
    masterStudent: "Postgraduate",
    phdStudent: "PhD Student",
    bachelor: "Bachelor",
    master: "Master",
    phd: "PhD",
  },
  project: {
    fellows: "Research Fellows",
    open: "Open Resources",
    achievements: "Achievements",
    publications: "Publications",
    other: "Other Results"
  },
}
