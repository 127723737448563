<template>
  <div class="nav-background">
    <div class="nav-main">
      <a @click="handleSmMenu('/')" style="margin: auto 20px">
        <img src="../assets/logo-isl.svg" class="isl-logo" />
        <div
          v-if="$store.state.screenSize == 'sm'"
          class="lab-name"
          style="font-size: 16px; text-align: left"
        >
          <div v-if="$i18n.locale == 'zh'">智能系统实验室</div>
          <div v-else>
            Intelligent Systems<br />
            Laboratory
          </div>
        </div>
        <div v-else class="lab-name" style="font-size: 26px">
          {{ $t("navigation.labName") }}
        </div>
      </a>
      <a-menu
        v-if="$store.state.screenSize == 'lg'"
        v-model="current"
        mode="horizontal"
        class="lg-menu"
        @select="handleLgMenu"
      >
        <!-- <a-menu-item key="partners"> Partners </a-menu-item> -->
        <a-menu-item key="changLang">
          {{ $t("navigation.changeLanguage") }}
        </a-menu-item>
        <a-menu-item key="/">
          {{ $t("navigation.index") }}
        </a-menu-item>
        <a-menu-item key="people">
          {{ $t("navigation.people") }}
        </a-menu-item>
        <a-menu-item key="news"> {{ $t("navigation.news") }} </a-menu-item>
        <a-menu-item key="projects">
          {{ $t("navigation.projects") }}
        </a-menu-item>
        <a-menu-item key="publications">
          {{ $t("navigation.publications") }}
        </a-menu-item>
        <a-menu-item key="representativepage">
          {{ $t("navigation.representativepage") }}
        </a-menu-item>
        <a-menu-item v-if="$store.state.token" key="logout"> 登出 </a-menu-item>
      </a-menu>
      <a-button
        v-else
        @click="handleMenuBtn"
        style="margin-right: 20px; vertical-align: middle"
      >
        <a-icon type="menu" />
      </a-button>
    </div>
    <div v-if="$store.state.screenSize !== 'lg' && showSmMenu" class="sm-menu">
      <div class="sm-menu-item" @click="handleSmMenu('changLang')">
        {{ $t("navigation.changeLanguage") }}
      </div>
      <div class="sm-menu-item" @click="handleSmMenu('/')">
        {{ $t("navigation.index") }}
      </div>
      <div class="sm-menu-item" @click="handleSmMenu('people')">
        {{ $t("navigation.people") }}
      </div>
      <div class="sm-menu-item" @click="handleSmMenu('news')">
        {{ $t("navigation.news") }}
      </div>
      <div class="sm-menu-item" @click="handleSmMenu('projects')">
        {{ $t("navigation.projects") }}
      </div>
      <div class="sm-menu-item" @click="handleSmMenu('publications')">
        {{ $t("navigation.publications") }}
      </div>
      <div class="sm-menu-item" @click="handleSmMenu('representativepage')">
        {{ $t("navigation.representativepage") }}
      </div>
      <div
        v-if="$store.state.token"
        class="sm-menu-item"
        @click="handleSmMenu('logout')"
      >
        登出
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Menu, Icon, Button } from "ant-design-vue";
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Button);

export default {
  name: "NavigationBar",
  created() {
    this.$i18n.locale = window.navigator.language.split("-")[0];
  },

  data() {
    return {
      showSmMenu: false,
      changeLang: () => {
        this.$i18n.locale = this.$i18n.locale == "en" ? "zh" : "en";
      },
      handleLgMenu: (para) => {
        const { key } = para;
        if (key == "changLang") {
          this.changeLang();
        } else if (key == "logout") {
          this.$store.commit("removeUserInfo");
          this.$router.go(0);
        } else {
          this.$router.push(key);
        }
        para.selectedKeys.length = 0;
      },
      handleMenuBtn: () => {
        this.showSmMenu = !this.showSmMenu;
      },
      handleSmMenu: (key) => {
        if (key == "changLang") {
          this.changeLang();
        } else if (key == "logout") {
          this.$store.commit("removeUserInfo");
          this.$router.go(0);
        }
        else {
          this.$router.push(key);
        }
        this.showSmMenu = false;
      },
      current: ["mail"],
    };
  },
};
</script>
<style scoped>
.nav-background {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  min-height: var(--navigation-bar-height);
  background-color: var(--element-bg-color);
  box-shadow: 0 2px 6px 0 black;
}
.nav-main {
  max-width: 1200px;
  min-height: 56px;
  margin: auto;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.isl-logo {
  display: inline-block;
  width: 80px;
  vertical-align: middle;
  margin-right: 10px;
}
.lab-name {
  display: inline-block;
  font-size: 26px;
  vertical-align: middle;
}
.lg-menu {
  vertical-align: middle;
  border: none;
}
.sm-menu {
  margin: 10px auto;
}
.sm-menu-item {
  margin: 10px auto;
}
</style>
